


.close {
    position: absolute;
    right: 32px;
    top: 32px;
    width: 32px;
    height: 32px;
    opacity: 0.3;
  }
  .close:hover {
    opacity: 1;
  }
  .close:before, .close:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 33px;
    width: 2px;
    background-color: #333;
  }
  .close:before {
    transform: rotate(45deg);
  }
  .close:after {
    transform: rotate(-45deg);
  }


  .knowmorepage{  
      width: 100vw;
      height: 95vh;
      /* background-color: aqua;  */
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 30px;
      margin-bottom: 50px;
  }

  .knowmorecontainer{
    max-width: 1100px;
    width: 90%;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    height: 80%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }

  .headingdetails{
    margin-top: 30px;
  }

  .knowmoregrid{
    margin-top: 30px;
    width: 90%;
    height: 90%;
    display: grid;
    align-items: center;
    justify-items: center;
    margin-bottom: 20px;
    grid-template-columns: repeat( auto-fit, minmax(250px, 1fr) );
  }

  .subheads{
    font-size: 12px;
    color: black;
  }

  .subans{
    color: #482ff7;
    font-size: 18px;
  }