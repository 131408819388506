
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300&display=swap');

*{
    margin: 0%;
    padding: 0%;
    box-sizing: border-box;
}

html,body{
    height: 100%;
}

.mypostspage{
    height: 100%;
    width: 100%;
    /* display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; */
}

.heading{
    display: flex;
    width: 200px;
    justify-content: space-evenly;
    margin-left: 20px;
    margin-top: 120px;
    align-items: center;
    position: relative;
    left:30px
}

.heading h1{
    font-size: 29px;
}

.badge {
    background-color: #482ff7;
    color: white;
    width: 30px;
    height: 30px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mypostbody{
    display: flex;
    width: 100%;
    height: 90%;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    z-index: -8;
}

.mypostsgrid{
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(325px, 1fr) );
    width: 85%;
    /* background-color: violet; */
    justify-items: center;
    gap: 40px;
    margin-bottom: 80px;
}

.postsbox{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 300px;
    width: 300px;
    background-color: #e3e6ec;;
    border-radius: 45px 45px 45px 45px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    /* z-index: ; */
    /* z-index: -1; */
}

.postsbox1{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    bottom: 30px;

}

.postsbox2{
    position: relative;
    bottom: 30px;
    width: 80%;
    font-size: 11px;
    /* font-family: 'Roboto', sans-serif; */
    font-family: 'Quicksand', sans-serif;
    /* font-weight: lighter; */
}

.wrapper23
{
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 35px;
}

.location
{
    display: flex;
    justify-content: center;
    align-items: center;
}

.date{
    color: #482ff7;
}

.wrapper1{
    display: flex;
    width: 80%;
    justify-content: space-evenly;
    align-items: center;
}

.knowmorebtn{
    padding: 8px 25px;
    border-radius: 10px;
    cursor: pointer;
    /* z-index: ; */
}

.deletebtn{
    color: #CA0B0B;
    position: relative;
    top: 30px;
    left: 10px;
    cursor: pointer;
}

.nodata{
    width: 100%;
    height: 70vh;
    /* background-color: aqua; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.nodata img{
    width: 500px;
    height: auto;
}

.nodata h3{
    font-size: 28px;
}

.nodata p {
    font-size: 18px;
    margin: 10px;
}

@media only screen and ( max-width: 567px ){
    .nodata img{
        width: 350px;
        height: auto;
    }

    .nodata h3{
        font-size: 22px;
    }
    
    .nodata p {
        font-size: 16px;
        margin: 10px;
    }
}