*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

.signuppage{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
}

.signuppage h1 {
    margin-top: 100px;
    margin-bottom: 40px;
    font-size: 36px;
}

.signupcontainer{
    display: flex;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    width: 1000px;
    height: 500px;
    border-radius: 20px;
    align-items: center;
}

.formcontainer{
    display: flex;
    flex-direction: column;
    width: 50%;
    justify-content: center;
    align-items: center;
}

.forminnercontainer{
    height: 80%;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.forminnercontainer input{
    border: 1px solid black;
    width: 250px;
    height: 40px;
    margin: 15px;
    padding: 10px;
}

.forminnercontainer button{
    padding: 10px 20px;
    background-color: #482ff7;
    color: white;
    font-size: 16px;
    cursor: pointer;
}

.forminnercontainer h2{
    font-size: 26px;
}

.logocontainer{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    /* background-color: #918bfa; */
    border-radius: 20px;

}

.logocontainer img{
    height: auto;
    width: 450px;
}

.linebtw{
    height: 70%;
    width: 3px;
    /* background-color: rgb(41, 41, 41); */
    border: 1.5px dashed #999;
    border-radius: 50px;    
}

.errtxt{
    color: red;
    font-size: 16px;
    font-style: italic;
    font-weight: bold;
}




@media only screen and (max-width: 1077px){
    .signuppage{
        /* background-color: aqua; */
    }
    
    .signupcontainer{
        width: 800px;
    }
}

@media only screen and (max-width: 862px){
    .signupcontainer{
        width: 600px;
    }

    .logocontainer img{
        height: auto;
        width: 350px;
    }
}


@media only screen and (max-width: 700px){
    .signupcontainer{
        width: 300px;
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
       
    }

    .logocontainer img{
        height: auto;
        width: 250px;
        /* display: none; */
        /* display: none; */
    }

    .logocontainer{
        /* display: none; */
    }

    .linebtw{
        display: none;
    }

    .signuppage h1 {
        margin-top: 15px;
        margin-bottom: 20px;
        font-size: 25px;
    }

    .formcontainer{
        margin-bottom: 30px;
    }
}