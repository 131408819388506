@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,500;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Dancing+Script&family=Open+Sans:wght@300;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Pacifico&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Comforter+Brush&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


*{
    margin: 0px;
    border: 0px;
    box-sizing: border-box;
}

.section1{
/* background-color: aqua; */
    height: 650px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

}

.imagecontainer{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.landimg{
    height: auto;
    width: 90%;
    position: relative;
    /* right: 80px; */
    left: 60px;
}

.textcontainer{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 200px;align-items: center;
}

.textcontainer h1{
    font-size: 45px;
}

.textcontainer p{
    font-size: 20px;
}

.textcontainer button{
    width: 120px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top:10px ;
    padding-bottom: 10px;
    cursor: pointer;
    font-size: 16px;
    background-color: #482ff7;
    color: white;
    border-radius: 5px;
}
hr{
    height: 5px;
    color: black;
}

.line{
    margin-top: 50px;
    height: 2px;
    width: 60%;
    background-color: black;
    border-radius: 50px;
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}

.section2{
    height: 500px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 130px;
    flex-direction: column;
}

.title{
    font-size: 26px;
}

.cardcontainer{
    height: 350px;
    width: 90%;
    margin: 50px;
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(300px, 1fr) );
    align-items: center;
    justify-items: center;
    justify-content: center;
}

.cards
{
    height: 100%;
    width: 350px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-color: #f5f6fb;;
    border-radius: 45px 45px 45px 0px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.cards img {
    height: auto;
    width: 250px;
}

.imgdiv{
    display: flex;
    justify-content: center;
    align-items: center;
}

.cards h3{
    font-size: 20px;
}

.cards p{
    font-size: 15px;
    position: relative;
    bottom: 20px;
}


@media only screen and (max-width: 1568px){
    .landingpage{
        /* background-color: aqua; */
    }

    .textcontainer h1{
        font-size: 45px;
        text-align: center;
    }
    
    .textcontainer p{
        font-size: 20px;
        text-align: center;

    }

    .landimg{
        height: auto;
        width: 90%;
        position: relative;
        /* right: 30px; */
        left: 0px;
    }
}

@media only screen and (max-width: 1568px){
    .cards{
        height: 90%;
        width: 300px;
    }
}

@media only screen and (max-width: 1160px){
    .cards{
        height: 70%;
        width: 250px;
    }

    .textcontainer h1{
        font-size: 38px;
    }
    
    .textcontainer p{
        font-size: 17px;
    }

    .cards img {
        height: auto;
        width: 170px;
    }

    .cards h3{
        font-size: 16px;
    }
    
    .cards p{
        font-size: 12px;
        position: relative;
        bottom: 20px;
        text-align: center;
    }
}

@media only screen and (max-width: 1012px){

    .section2{
        height: 1200px;
    }

    .cardcontainer{
        display: grid;
        grid-gap: 40px;
        gap: 40px;
        height: 120%;
        grid-template-columns: repeat( auto-fit, minmax(500px, 1fr) );
    }

    .cards{
        height: 100%;
        width: 350px;
    }

    .cards img {
        height: auto;
        width: 170px;
    }

    .cards h3{
        font-size: 16px;
    }
    
    .cards p{
        font-size: 12px;
        position: relative;
        bottom: 20px;
        text-align: center;
    }
}

@media only screen and (max-width: 815px){

    .section1{
        /* background-color: aqua; */
            height: 650px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;
        
        }

        .textcontainer{
            width: 80%;
        }

        .textcontainer h1{
            font-size: 35px;
        }
        
        .textcontainer p{
            font-size: 17px;
            margin: 35px;
        }
        
        
    .landimg{
        height: auto;
        width: 330px;
        position: relative;
        /* right: 30px; */
        left: 0px;
    }

    .cards{
        height: 100%;
        width: 280px;
    }

    .cards h3{
        font-size: 20px;
    }
    
    .cards p{
        font-size: 16px;
    }
        
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
    font-family: 'Roboto', sans-serif;
}

li {
    list-style: none;
}

a {
    text-decoration: none;
}

.header{
    border-bottom: 1px solid #E2E8F0;
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.5rem;
    height: 70px;
    position: fixed;
    width: 100%;
    background-color: rgb(255, 255, 255);
    z-index: 8;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.hamburger {
    display: none;
}

.bar {
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px auto;
    transition: all 0.3s ease-in-out;
    background-color: #101010;
}

.nav-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav-item {
    margin-left: 5rem;
}

.nav-link{
    font-size: 1.6rem;
    font-weight: 400;
    color: #475569;
    padding: 10px;
}

.nav-link:hover{
    color: white;
    background-color: black;
    border-radius: 5px;
    padding: 10px;
}

.nav-logo {
    font-size: 2.1rem;
    font-weight: 500;
    color: #482ff7;
}

.loginbtn{
    color: white;
    background-color: black;
    border-radius: 5px;
    /* padding: 10px; */
    font-size: 1.6rem;
    font-weight: 400;
    padding: 3px;
}

@media only screen and (max-width: 768px) {
    .nav-menu {
        position: fixed;
        left: -100%;
        top: 5rem;
        flex-direction: column;
        background-color: #fff;
        width: 100%;
        border-radius: 10px;
        text-align: center;
        transition: 0.3s;
        box-shadow:
            0 10px 27px rgba(0, 0, 0, 0.05);
            z-index: 6;
    }

    .nav-menu.active {
        left: 0;
    }

    .nav-item {
        margin: 2.5rem 0;
    }

    .hamburger {
        display: block;
        cursor: pointer;
    }

    .hamburger.active .bar:nth-child(2) {
        opacity: 0;
    }

    .hamburger.active .bar:nth-child(1) {
        -webkit-transform: translateY(8px) rotate(45deg);
                transform: translateY(8px) rotate(45deg);
    }

    .hamburger.active .bar:nth-child(3) {
        -webkit-transform: translateY(-8px) rotate(-45deg);
                transform: translateY(-8px) rotate(-45deg);
    }
}
*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

.aboutuspage{
    margin-top: 80px;
    height: 100%;
    width: 100wh;
    /* background-color: aqua; */
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.aboutheading{
    font-size: 27px;
    margin-top: 30px;
}

.aboutcontainer{
    height: 70%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 700px;
    max-width: 80%;
    padding: 30px;
    margin-top: 40px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    margin-bottom: 80px;
}

.aboutimgcont{
    height: 140px;
    width: 140px;
    border-radius: 50%;
    background-color: rgb(175, 175, 175);
    margin-bottom: 30px;
}

.aboutimgcont img{
    height: 140px;
    width: 140px;
    border-radius: 50%;
}
.aboutcontainer p{
    font-size: 32px;
     font-family: 'Dancing Script', cursive;
}

.findme{
    width: 100%;
    margin-top: 40px;

}

.findme h3{
    font-size: 19px;
}

.instaicon{
    -webkit-transform: scale(2.7);
            transform: scale(2.7);
    margin-top: 20px;
    color: black;
    margin-right: 30px;
    margin-left: 10px;
}

.linkedinicon{
    -webkit-transform: scale(2.7);
            transform: scale(2.7);
    margin-top: 20px;
    color: black;
}
*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

.signuppage{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
}

.signuppage h1 {
    margin-top: 100px;
    margin-bottom: 40px;
    font-size: 36px;
}

.signupcontainer{
    display: flex;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    width: 1000px;
    height: 500px;
    border-radius: 20px;
    align-items: center;
}

.formcontainer{
    display: flex;
    flex-direction: column;
    width: 50%;
    justify-content: center;
    align-items: center;
}

.forminnercontainer{
    height: 80%;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.forminnercontainer input{
    border: 1px solid black;
    width: 250px;
    height: 40px;
    margin: 15px;
    padding: 10px;
}

.forminnercontainer button{
    padding: 10px 20px;
    background-color: #482ff7;
    color: white;
    font-size: 16px;
    cursor: pointer;
}

.forminnercontainer h2{
    font-size: 26px;
}

.logocontainer{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    /* background-color: #918bfa; */
    border-radius: 20px;

}

.logocontainer img{
    height: auto;
    width: 450px;
}

.linebtw{
    height: 70%;
    width: 3px;
    /* background-color: rgb(41, 41, 41); */
    border: 1.5px dashed #999;
    border-radius: 50px;    
}

.errtxt{
    color: red;
    font-size: 16px;
    font-style: italic;
    font-weight: bold;
}




@media only screen and (max-width: 1077px){
    .signuppage{
        /* background-color: aqua; */
    }
    
    .signupcontainer{
        width: 800px;
    }
}

@media only screen and (max-width: 862px){
    .signupcontainer{
        width: 600px;
    }

    .logocontainer img{
        height: auto;
        width: 350px;
    }
}


@media only screen and (max-width: 700px){
    .signupcontainer{
        width: 300px;
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
       
    }

    .logocontainer img{
        height: auto;
        width: 250px;
        /* display: none; */
        /* display: none; */
    }

    .logocontainer{
        /* display: none; */
    }

    .linebtw{
        display: none;
    }

    .signuppage h1 {
        margin-top: 15px;
        margin-bottom: 20px;
        font-size: 25px;
    }

    .formcontainer{
        margin-bottom: 30px;
    }
}
*{
    margin: 0%;
    padding: 0%;
    box-sizing: border-box;
}

.mypostpage{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    min-height: 1000px;
}

.mypostbgcontainer{
    position: relative;
    bottom: 50px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 10px;
    height: 800px;
    width: 900px;
    margin: 40px;
    /* padding: 40px; */
    /* border: 40px; */
    display: flex;
    justify-content: center;
    align-items: center;

}

.mypostcontainer{
    height: 90%;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.companydetails{
    /* background-color: aqua; */
    width: 100%;
    height: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.companydetails input{
    border: 1px solid black;
    width: 350px;
    height: 40px;
    margin: 15px;
    padding: 10px;
    border-radius: 5px;
    font-size: 14px;
    color: #482ff7;
}

.companydetails h3{
    font-size: 15px;
}

.wrapper{
    display: flex;
    width: 100%;
    justify-content: space-evenly;
}



.jobrequirements{
    /* background-color: aqua; */
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.jobrequirements input{
    border: 1px solid black;
    width: 350px;
    height: 40px;
    margin: 15px;
    padding: 10px;
    border-radius: 5px;
    color: #482ff7;
    font-size: 14px;
}



.jobrequirements select{
    border: 1px solid black;
    width: 350px;
    height: 40px;
    margin: 15px;
    padding: 10px;
    border-radius: 5px;
}

.jobrequirements h3{
    font-size: 16px;
    color: #000;
}
 
button{
    padding: 10px 40px;
    background-color: #482ff7;
    color: white;
    font-size: 16px;
    cursor: pointer;
}

@media only screen and (max-width: 900px){
    .mypostbgcontainer{
        /* background-color: aqua; */
    }

    .companydetails input{
        width: 280px;
    }

    .jobrequirements input{
        width: 280px;
    }

    .jobrequirements select{
        width: 280px;
    }
}

@media only screen and (max-width: 747px){
    .mypostbgcontainer{
    }

    .companydetails input{
        width: 220px;
    }

    .jobrequirements input{
        width: 220px;
    }

    .jobrequirements select{
        width: 220px;
    }
}

@media only screen and (max-width: 620px){

    .mypostpage{
        height: 120%;
    }

    .mypostbgcontainer{
        height: 1350px;
        position: relative;
        top: 10px;
        align-items: flex-start;
        margin-bottom: 40px;
        
    }

    .mypostcontainer{
        position: relative;
        top: 50px;

    }

    .companydetails{
        width: 100%;
        margin-top: 30px;
    }

    .jobrequirements{
        width: 100%;
        margin-top: 30px;


    }

    .companydetails input{
        width: 100%;
        position: relative;
        right: 16px;
    }

    .jobrequirements input{
        width: 100%;
        position: relative;
        right: 16px;
    }

    .jobrequirements select{
        width: 100%;
        position: relative;
        right: 16px;
    }

    .wrapper{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .div1,.div2,.div3,.div4,.div5,.div6,.div7,.div8,.div9{
        width: 90%;
    }
}




.close {
    position: absolute;
    right: 32px;
    top: 32px;
    width: 32px;
    height: 32px;
    opacity: 0.3;
  }
  .close:hover {
    opacity: 1;
  }
  .close:before, .close:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 33px;
    width: 2px;
    background-color: #333;
  }
  .close:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }
  .close:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }


  .knowmorepage{  
      width: 100vw;
      height: 95vh;
      /* background-color: aqua;  */
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 30px;
      margin-bottom: 50px;
  }

  .knowmorecontainer{
    max-width: 1100px;
    width: 90%;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    height: 80%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }

  .headingdetails{
    margin-top: 30px;
  }

  .knowmoregrid{
    margin-top: 30px;
    width: 90%;
    height: 90%;
    display: grid;
    align-items: center;
    justify-items: center;
    margin-bottom: 20px;
    grid-template-columns: repeat( auto-fit, minmax(250px, 1fr) );
  }

  .subheads{
    font-size: 12px;
    color: black;
  }

  .subans{
    color: #482ff7;
    font-size: 18px;
  }

*{
    margin: 0%;
    padding: 0%;
    box-sizing: border-box;
}

html,body{
    height: 100%;
}

.mypostspage{
    height: 100%;
    width: 100%;
    /* display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; */
}

.heading{
    display: flex;
    width: 200px;
    justify-content: space-evenly;
    margin-left: 20px;
    margin-top: 120px;
    align-items: center;
    position: relative;
    left:30px
}

.heading h1{
    font-size: 29px;
}

.badge {
    background-color: #482ff7;
    color: white;
    width: 30px;
    height: 30px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mypostbody{
    display: flex;
    width: 100%;
    height: 90%;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    z-index: -8;
}

.mypostsgrid{
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(325px, 1fr) );
    width: 85%;
    /* background-color: violet; */
    justify-items: center;
    grid-gap: 40px;
    gap: 40px;
    margin-bottom: 80px;
}

.postsbox{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 300px;
    width: 300px;
    background-color: #e3e6ec;;
    border-radius: 45px 45px 45px 45px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    /* z-index: ; */
    /* z-index: -1; */
}

.postsbox1{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    bottom: 30px;

}

.postsbox2{
    position: relative;
    bottom: 30px;
    width: 80%;
    font-size: 11px;
    /* font-family: 'Roboto', sans-serif; */
    font-family: 'Quicksand', sans-serif;
    /* font-weight: lighter; */
}

.wrapper23
{
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 35px;
}

.location
{
    display: flex;
    justify-content: center;
    align-items: center;
}

.date{
    color: #482ff7;
}

.wrapper1{
    display: flex;
    width: 80%;
    justify-content: space-evenly;
    align-items: center;
}

.knowmorebtn{
    padding: 8px 25px;
    border-radius: 10px;
    cursor: pointer;
    /* z-index: ; */
}

.deletebtn{
    color: #CA0B0B;
    position: relative;
    top: 30px;
    left: 10px;
    cursor: pointer;
}

.nodata{
    width: 100%;
    height: 70vh;
    /* background-color: aqua; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.nodata img{
    width: 500px;
    height: auto;
}

.nodata h3{
    font-size: 28px;
}

.nodata p {
    font-size: 18px;
    margin: 10px;
}

@media only screen and ( max-width: 567px ){
    .nodata img{
        width: 350px;
        height: auto;
    }

    .nodata h3{
        font-size: 22px;
    }
    
    .nodata p {
        font-size: 16px;
        margin: 10px;
    }
}
/* .loader {
    --background: linear-gradient(135deg, #23C4F8, #275EFE);
    --shadow: rgba(39, 94, 254, 0.28);
    --text: #6C7486;
    --page: rgba(255, 255, 255, 0.36);
    --page-fold: rgba(255, 255, 255, 0.52);
    --duration: 3s;
    width: 200px;
    height: 140px;
    position: relative;
  }
  .loader:before, .loader:after {
    --r: -6deg;
    content: "";
    position: absolute;
    bottom: 8px;
    width: 120px;
    top: 80%;
    box-shadow: 0 16px 12px var(--shadow);
    transform: rotate(var(--r));
  }
  .loader:before {
    left: 4px;
  }
  .loader:after {
    --r: 6deg;
    right: 4px;
  }
  .loader div {
    width: 100%;
    height: 100%;
    border-radius: 13px;
    position: relative;
    z-index: 1;
    perspective: 600px;
    box-shadow: 0 4px 6px var(--shadow);
    background-image: var(--background);
  }
  .loader div ul {
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;
  }
  .loader div ul li {
    --r: 180deg;
    --o: 0;
    --c: var(--page);
    position: absolute;
    top: 10px;
    left: 10px;
    transform-origin: 100% 50%;
    color: var(--c);
    opacity: var(--o);
    transform: rotateY(var(--r));
    -webkit-animation: var(--duration) ease infinite;
            animation: var(--duration) ease infinite;
  }
  .loader div ul li:nth-child(2) {
    --c: var(--page-fold);
    -webkit-animation-name: page-2;
            animation-name: page-2;
  }
  .loader div ul li:nth-child(3) {
    --c: var(--page-fold);
    -webkit-animation-name: page-3;
            animation-name: page-3;
  }
  .loader div ul li:nth-child(4) {
    --c: var(--page-fold);
    -webkit-animation-name: page-4;
            animation-name: page-4;
  }
  .loader div ul li:nth-child(5) {
    --c: var(--page-fold);
    -webkit-animation-name: page-5;
            animation-name: page-5;
  }
  .loader div ul li svg {
    width: 90px;
    height: 120px;
    display: block;
  }
  .loader div ul li:first-child {
    --r: 0deg;
    --o: 1;
  }
  .loader div ul li:last-child {
    --o: 1;
  }
  .loader span {
    display: block;
    left: 0;
    right: 0;
    top: 100%;
    margin-top: 20px;
    text-align: center;
    color: var(--text);
  }
  
  @-webkit-keyframes page-2 {
    0% {
      transform: rotateY(180deg);
      opacity: 0;
    }
    20% {
      opacity: 1;
    }
    35%, 100% {
      opacity: 0;
    }
    50%, 100% {
      transform: rotateY(0deg);
    }
  }
  
  @keyframes page-2 {
    0% {
      transform: rotateY(180deg);
      opacity: 0;
    }
    20% {
      opacity: 1;
    }
    35%, 100% {
      opacity: 0;
    }
    50%, 100% {
      transform: rotateY(0deg);
    }
  }
  @-webkit-keyframes page-3 {
    15% {
      transform: rotateY(180deg);
      opacity: 0;
    }
    35% {
      opacity: 1;
    }
    50%, 100% {
      opacity: 0;
    }
    65%, 100% {
      transform: rotateY(0deg);
    }
  }
  @keyframes page-3 {
    15% {
      transform: rotateY(180deg);
      opacity: 0;
    }
    35% {
      opacity: 1;
    }
    50%, 100% {
      opacity: 0;
    }
    65%, 100% {
      transform: rotateY(0deg);
    }
  }
  @-webkit-keyframes page-4 {
    30% {
      transform: rotateY(180deg);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    65%, 100% {
      opacity: 0;
    }
    80%, 100% {
      transform: rotateY(0deg);
    }
  }
  @keyframes page-4 {
    30% {
      transform: rotateY(180deg);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    65%, 100% {
      opacity: 0;
    }
    80%, 100% {
      transform: rotateY(0deg);
    }
  }
  @-webkit-keyframes page-5 {
    45% {
      transform: rotateY(180deg);
      opacity: 0;
    }
    65% {
      opacity: 1;
    }
    80%, 100% {
      opacity: 0;
    }
    95%, 100% {
      transform: rotateY(0deg);
    }
  }
  @keyframes page-5 {
    45% {
      transform: rotateY(180deg);
      opacity: 0;
    }
    65% {
      opacity: 1;
    }
    80%, 100% {
      opacity: 0;
    }
    95%, 100% {
      transform: rotateY(0deg);
    }
  }
  html {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
  }
  
  * {
    box-sizing: inherit;
  }
  *:before, *:after {
    box-sizing: inherit;
  }
  
 .loader{
     position: absolute;
     left: 50%;
     top: 500%;
     transform: translate(-50%,-50%);

 } */


  
  .loading {
    display: flex;
    position: absolute;
    left: 50%;
    top: 700%;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
  }
  .loading .dot {
    position: relative;
    width: 2em;
    height: 2em;
    margin: 0.8em;
    border-radius: 50%;
  }
  .loading .dot::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background: inherit;
    border-radius: inherit;
    -webkit-animation: wave 2s ease-out infinite;
            animation: wave 2s ease-out infinite;
  }
  .loading .dot:nth-child(1) {
    background: #7ef9ff;
  }
  .loading .dot:nth-child(1)::before {
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
  }
  .loading .dot:nth-child(2) {
    background: #89cff0;
  }
  .loading .dot:nth-child(2)::before {
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;
  }
  .loading .dot:nth-child(3) {
    background: #4682b4;
  }
  .loading .dot:nth-child(3)::before {
    -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s;
  }
  .loading .dot:nth-child(4) {
    background: #0f52ba;
  }
  .loading .dot:nth-child(4)::before {
    -webkit-animation-delay: 0.8s;
            animation-delay: 0.8s;
  }
  .loading .dot:nth-child(5) {
    background: #000080;
  }
  .loading .dot:nth-child(5)::before {
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
  }
  
  @-webkit-keyframes wave {
    50%, 75% {
      -webkit-transform: scale(2.5);
              transform: scale(2.5);
    }
    80%, 100% {
      opacity: 0;
    }
  }
  
  @keyframes wave {
    50%, 75% {
      -webkit-transform: scale(2.5);
              transform: scale(2.5);
    }
    80%, 100% {
      opacity: 0;
    }
  }
