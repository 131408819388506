/* .loader {
    --background: linear-gradient(135deg, #23C4F8, #275EFE);
    --shadow: rgba(39, 94, 254, 0.28);
    --text: #6C7486;
    --page: rgba(255, 255, 255, 0.36);
    --page-fold: rgba(255, 255, 255, 0.52);
    --duration: 3s;
    width: 200px;
    height: 140px;
    position: relative;
  }
  .loader:before, .loader:after {
    --r: -6deg;
    content: "";
    position: absolute;
    bottom: 8px;
    width: 120px;
    top: 80%;
    box-shadow: 0 16px 12px var(--shadow);
    transform: rotate(var(--r));
  }
  .loader:before {
    left: 4px;
  }
  .loader:after {
    --r: 6deg;
    right: 4px;
  }
  .loader div {
    width: 100%;
    height: 100%;
    border-radius: 13px;
    position: relative;
    z-index: 1;
    perspective: 600px;
    box-shadow: 0 4px 6px var(--shadow);
    background-image: var(--background);
  }
  .loader div ul {
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;
  }
  .loader div ul li {
    --r: 180deg;
    --o: 0;
    --c: var(--page);
    position: absolute;
    top: 10px;
    left: 10px;
    transform-origin: 100% 50%;
    color: var(--c);
    opacity: var(--o);
    transform: rotateY(var(--r));
    -webkit-animation: var(--duration) ease infinite;
            animation: var(--duration) ease infinite;
  }
  .loader div ul li:nth-child(2) {
    --c: var(--page-fold);
    -webkit-animation-name: page-2;
            animation-name: page-2;
  }
  .loader div ul li:nth-child(3) {
    --c: var(--page-fold);
    -webkit-animation-name: page-3;
            animation-name: page-3;
  }
  .loader div ul li:nth-child(4) {
    --c: var(--page-fold);
    -webkit-animation-name: page-4;
            animation-name: page-4;
  }
  .loader div ul li:nth-child(5) {
    --c: var(--page-fold);
    -webkit-animation-name: page-5;
            animation-name: page-5;
  }
  .loader div ul li svg {
    width: 90px;
    height: 120px;
    display: block;
  }
  .loader div ul li:first-child {
    --r: 0deg;
    --o: 1;
  }
  .loader div ul li:last-child {
    --o: 1;
  }
  .loader span {
    display: block;
    left: 0;
    right: 0;
    top: 100%;
    margin-top: 20px;
    text-align: center;
    color: var(--text);
  }
  
  @-webkit-keyframes page-2 {
    0% {
      transform: rotateY(180deg);
      opacity: 0;
    }
    20% {
      opacity: 1;
    }
    35%, 100% {
      opacity: 0;
    }
    50%, 100% {
      transform: rotateY(0deg);
    }
  }
  
  @keyframes page-2 {
    0% {
      transform: rotateY(180deg);
      opacity: 0;
    }
    20% {
      opacity: 1;
    }
    35%, 100% {
      opacity: 0;
    }
    50%, 100% {
      transform: rotateY(0deg);
    }
  }
  @-webkit-keyframes page-3 {
    15% {
      transform: rotateY(180deg);
      opacity: 0;
    }
    35% {
      opacity: 1;
    }
    50%, 100% {
      opacity: 0;
    }
    65%, 100% {
      transform: rotateY(0deg);
    }
  }
  @keyframes page-3 {
    15% {
      transform: rotateY(180deg);
      opacity: 0;
    }
    35% {
      opacity: 1;
    }
    50%, 100% {
      opacity: 0;
    }
    65%, 100% {
      transform: rotateY(0deg);
    }
  }
  @-webkit-keyframes page-4 {
    30% {
      transform: rotateY(180deg);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    65%, 100% {
      opacity: 0;
    }
    80%, 100% {
      transform: rotateY(0deg);
    }
  }
  @keyframes page-4 {
    30% {
      transform: rotateY(180deg);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    65%, 100% {
      opacity: 0;
    }
    80%, 100% {
      transform: rotateY(0deg);
    }
  }
  @-webkit-keyframes page-5 {
    45% {
      transform: rotateY(180deg);
      opacity: 0;
    }
    65% {
      opacity: 1;
    }
    80%, 100% {
      opacity: 0;
    }
    95%, 100% {
      transform: rotateY(0deg);
    }
  }
  @keyframes page-5 {
    45% {
      transform: rotateY(180deg);
      opacity: 0;
    }
    65% {
      opacity: 1;
    }
    80%, 100% {
      opacity: 0;
    }
    95%, 100% {
      transform: rotateY(0deg);
    }
  }
  html {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
  }
  
  * {
    box-sizing: inherit;
  }
  *:before, *:after {
    box-sizing: inherit;
  }
  
 .loader{
     position: absolute;
     left: 50%;
     top: 500%;
     transform: translate(-50%,-50%);

 } */


  
  .loading {
    display: flex;
    position: absolute;
    left: 50%;
    top: 700%;
    transform: translate(-50%,-50%);
  }
  .loading .dot {
    position: relative;
    width: 2em;
    height: 2em;
    margin: 0.8em;
    border-radius: 50%;
  }
  .loading .dot::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background: inherit;
    border-radius: inherit;
    animation: wave 2s ease-out infinite;
  }
  .loading .dot:nth-child(1) {
    background: #7ef9ff;
  }
  .loading .dot:nth-child(1)::before {
    animation-delay: 0.2s;
  }
  .loading .dot:nth-child(2) {
    background: #89cff0;
  }
  .loading .dot:nth-child(2)::before {
    animation-delay: 0.4s;
  }
  .loading .dot:nth-child(3) {
    background: #4682b4;
  }
  .loading .dot:nth-child(3)::before {
    animation-delay: 0.6s;
  }
  .loading .dot:nth-child(4) {
    background: #0f52ba;
  }
  .loading .dot:nth-child(4)::before {
    animation-delay: 0.8s;
  }
  .loading .dot:nth-child(5) {
    background: #000080;
  }
  .loading .dot:nth-child(5)::before {
    animation-delay: 1s;
  }
  
  @keyframes wave {
    50%, 75% {
      transform: scale(2.5);
    }
    80%, 100% {
      opacity: 0;
    }
  }