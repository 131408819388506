@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&family=Open+Sans:wght@300;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comforter+Brush&display=swap');

*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

.aboutuspage{
    margin-top: 80px;
    height: 100%;
    width: 100wh;
    /* background-color: aqua; */
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.aboutheading{
    font-size: 27px;
    margin-top: 30px;
}

.aboutcontainer{
    height: 70%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 700px;
    max-width: 80%;
    padding: 30px;
    margin-top: 40px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    margin-bottom: 80px;
}

.aboutimgcont{
    height: 140px;
    width: 140px;
    border-radius: 50%;
    background-color: rgb(175, 175, 175);
    margin-bottom: 30px;
}

.aboutimgcont img{
    height: 140px;
    width: 140px;
    border-radius: 50%;
}
.aboutcontainer p{
    font-size: 32px;
     font-family: 'Dancing Script', cursive;
}

.findme{
    width: 100%;
    margin-top: 40px;

}

.findme h3{
    font-size: 19px;
}

.instaicon{
    transform: scale(2.7);
    margin-top: 20px;
    color: black;
    margin-right: 30px;
    margin-left: 10px;
}

.linkedinicon{
    transform: scale(2.7);
    margin-top: 20px;
    color: black;
}